<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Registra un colaborador
        </h3>
        <p class="ma-0 ml-1">
          Completa la información necesaria para poder registrar a tus
          colaboradores
        </p>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-container class="px-5">
            <v-form ref="createUser" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                  <v-row>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="text"
                        ref="firstName"
                        v-model="firstName"
                        :rules="firstNameRules"
                        label="Nombre(s)"
                        @keyup.enter.native="$refs.lastName.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="text"
                        ref="lastName"
                        v-model="lastName"
                        :rules="lastNameRules"
                        label="Apellidos"
                        @keyup.enter.native="$refs.email.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="email"
                        ref="email"
                        v-model="email"
                        :rules="emailRules"
                        label="Correo electrónico"
                        @keyup.enter.native="$refs.password.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        ref="password"
                        v-model="password"
                        :rules="passwordRules"
                        label="Contraseña"
                        @keyup.enter.native="$refs.phone.focus()"
                        :append-icon="
                          showPassword ? 'visibility' : 'visibility_off'
                        "
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="tel"
                        ref="phone"
                        v-model="phone"
                        :rules="phoneRules"
                        label="Número de teléfono"
                        @keyup.enter.native="$refs.whatsapp.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="tel"
                        ref="whatsapp"
                        v-model="whatsapp"
                        label="Whatsapp"
                        @keyup.enter.native="$refs.direction.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-text-field
                        outlined
                        type="text"
                        ref="direction"
                        v-model="direction"
                        label="Dirección"
                        @keyup.enter.native="$refs.notes.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-textarea
                        outlined
                        type="text"
                        ref="notes"
                        v-model="notes"
                        label="Notas del usuario"
                        rows="2"
                        auto-grow
                      ></v-textarea>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="12" md="12" lg="6" xl="3">
                      <v-select
                        v-model="selectedAccess"
                        :rules="accessRules"
                        item-text="title"
                        item-value="id"
                        :items="access"
                        label="Seleccione el tipo de acceso"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col
                      class="pb-8"
                      cols="12"
                      sm="12"
                      md="8"
                      offset-md="2"
                      lg="6"
                      offset-lg="3"
                      xl="4"
                      offset-xl="4"
                    >
                      <p>Imagen del usuario</p>
                      <v-card @click="uploadImage" elevation="0">
                        <app-upload-images
                          ref="uploadImages"
                        ></app-upload-images>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    max-width="300"
                    color="success"
                    :loading="isFetching"
                    @click="register"
                  >
                    Registrar usuario
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  emailValid,
  required,
  minLength,
  maxLength,
  onlyNumbers
} from "@/services/ValidatorService";
import UploadImages from "@/components/app/UploadImages";
import { mapActions, mapGetters, mapState } from "vuex";
import { showMessage } from "@/services/MessageService";

export default {
  data() {
    return {
      showPassword: false,
      valid: true,
      firstName: "",
      firstNameRules: [required],
      lastName: "",
      lastNameRules: [required],
      email: "",
      emailRules: [emailValid, required],
      password: "",
      passwordRules: [required, minLength(8)],
      phone: "",
      phoneRules: [required, minLength(10), maxLength(10), onlyNumbers],
      whatsapp: "",
      direction: "",
      notes: "",
      access: [
        { title: "Admin", id: 1 },
        { title: "Empleado", id: 2 }
      ],
      accessRules: [required],
      selectedAccess: null,
      isFetching: false
    };
  },
  computed: {
    ...mapState("app", ["imageData", "loadImage"]),
    ...mapState("user", ["data"]),
    ...mapGetters("user", ["businessID"])
  },
  methods: {
    ...mapActions("user/post", ["registerUser", "updateUserData"]),
    ...mapActions("app", ["uploadImageToStorage", "resetImageData"]),
    async register() {
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        phone: this.phone,
        whatsapp: this.whatsapp,
        direction: this.direction,
        notes: this.notes,
        admin: this.selectedAccess === 1 ? true : false,
        employee: this.selectedAccess === 2 ? true : false,
        business_id: this.data.business_id,
        photoURL: null
      };
      if (this.$refs.createUser.validate()) {
        const uid = await this.registerUser(data);
        if (uid) {
          if (this.imageData.blob) {
            const imageURL = await this.uploadImageToStorage({
              blob: this.imageData.blob,
              url: `business/${this.businessID}/users/profile_photos/${uid}`
            });
            await this.updateUserData({
              uid,
              photoURL: imageURL
            });
          }
          showMessage({
            message: "Se creo el usuario exitosamente",
            color: "success"
          });
          this.$router.push({ name: "users" });
        } else {
          this.$vuetify.goTo(0);
        }
      } else {
        this.$vuetify.goTo(0);
      }
    },
    uploadImage() {
      this.$refs.uploadImages.$refs.upload.click();
    }
  },
  beforeDestroy() {
    this.resetImageData();
  },
  components: {
    AppUploadImages: UploadImages
  }
};
</script>
